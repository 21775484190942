<template src="./template.html"></template>

<script>
import { mapGetters } from 'vuex'
import Draggable from 'vuedraggable'
import notificationMixin from 'src/mixins/notificationMixin'
import masterMixin from 'src/mixins/masterMixin'
import staffLabelApi from 'src/apis/workplace_masters/staff_label'
import timingControlMixin from 'src/mixins/timingControlMixin'
import windowMixin from 'src/mixins/windowMixin'
import ListSwitchPanel from '../ListSwitchPanel'
import { setPageName } from 'src/hooks/displayPageNameHook'
import { userHasWorkplaceRoleGteOwnerSync } from 'src/hooks/appRoleHook'

const api = staffLabelApi
const displayPageName = 'スタッフラベル'
const msgVars = { create: '作成', update: '編集', delete: '削除', update_disp_order: '表示順変更' }

export default {
  components: { Draggable, ListSwitchPanel },
  data() {
    return {
      displayPageName: displayPageName,
      searchParams: {
        displayListDisabledItem: false,
      },
      list: [],
      saveCandidate: {},
      deleteCandidate: {},
      showSaveModal: false,
      showDeleteModal: false,
      dispColorCandidates: [],
      isGteOwner: false,

      validations: this.getValidationMap(),

      dragOptions: {
        handle: '.grabbable',
        animation: 300,
        chosenClass: 'bg-color-default',
      },
      fixTable: true,
    }
  },
  computed: {
    ...mapGetters('user', {
      userId: 'id',
    }),
    ...mapGetters('workplace', {
      useCreateAndUpdateInfo: 'useCreateAndUpdateInfo',
      useStaffLabel: 'useStaffLabel',
    }),
    workplaceId() {
      return this.$route.params.workplaceId
    },
    hasList() {
      return this.list.length > 0
    },
    hasError() {
      return this.errors.any()
    },
    rowWidth() {
      return 1016 + this.useCreateAndUpdateInfo * 660
    },
  },
  mixins: [notificationMixin, masterMixin, timingControlMixin, windowMixin],
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    getValidationMap() {
      const ruleStr = { required: true, max: 255, noForbiddenChars: true }
      return {
        label_name: ruleStr,
      }
    },
    getApiParams() {
      let apiParams = {}
      if (!this.searchParams.displayListDisabledItem) {
        apiParams['is_enabled'] = true
      }
      return apiParams
    },
    clearErrors() {
      this.$validator.reset()
    },
    async getList() {
      const { data } = await api.index({
        workplaceId: this.workplaceId,
        params: this.getApiParams(),
      })
      this.list = data
    },
    async getListWithUpdatedSearchParams(params) {
      this.searchParams.displayListDisabledItem = params.displayDisabledModel
      await this.getList()
    },
    async getFullListCount() {
      const { data } = await api.index({
        workplaceId: this.workplaceId,
        params: {},
      })
      return data.length
    },
    async openSaveModal(item) {
      let saveCandidate = {}
      if (!item.id) {
        saveCandidate.isNew = true
        saveCandidate.disp_color = this.dispColorCandidates[0].key
        saveCandidate.is_enabled = true
      } else {
        saveCandidate = JSON.parse(JSON.stringify(item))
      }

      this.saveCandidate = saveCandidate
      this.showSaveModal = true
    },
    closeSaveModal() {
      this.saveCandidate = {}
      this.clearErrors()
      this.showSaveModal = false
    },
    async saveItem() {
      const isValid = await this.$validator.validate()
      if (!isValid) { return }

      const opType = this.saveCandidate.isNew ? 'create' : 'update'
      try {
        const reqObj = {
          workplaceId: this.workplaceId,
          data: this.saveCandidate,
        }
        await api[opType](reqObj)
        this.getList()
        this.closeSaveModal()
        this.notifySuccess1(`${displayPageName}を${msgVars[opType]}しました`)
        this.reloadOpener()
      } catch (err) {
        const errStatus = err.response.status
        const errRes = err.response.data || {}
        if (errStatus === 400 && errRes.reason === 'dup_label_name') {
          const msg = `そのラベル名は既に使用されています。`
          this.notifyError1(msg, { timeout: 5 * 1000 })
        } else {
          const errId = this.saveCandidate.isNew ? 'ERR00001' : 'ERR00002'
          const msg = `${displayPageName}の${msgVars[opType]}に失敗しました。` +
            `管理者に連絡してください。` +
            `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`
          this.notifyError1(msg, { err })
        }
      }
    },
    openDeleteModal(item) {
      this.deleteCandidate = item
      this.showDeleteModal = true
    },
    closeDeleteModal() {
      this.deleteCandidate = {}
      this.showDeleteModal = false
    },
    async deleteItem() {
      const opType = 'delete'
      try {
        const reqObj = {
          workplaceId: this.workplaceId,
          itemId: this.deleteCandidate.id,
        }
        await api[opType](reqObj)
        this.getList()
        this.closeDeleteModal()
        this.notifySuccess1(`${displayPageName}を${msgVars[opType]}しました`)
        this.reloadOpener()
      } catch (err) {
        const errStatus = err.response.status
        const errRes = err.response.data || {}
        if (errStatus === 400 && errRes.reason === 'in_use') {
          const msg = 'すでに使われているマスタです。削除できません。' +
            '無効化をおすすめします。'
          this.notifyError1(msg, { timeout: 5 * 1000 })
        } else {
          const errId = 'ERR00003'
          const msg = `${displayPageName}の${msgVars[opType]}に失敗しました。` +
            `管理者に連絡してください。` +
            `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`
          this.notifyError1(msg, { err })
        }
      }
    },
    isDraggable(e) {
      return true
    },
    async updateDispOrder() {
      const opType = 'update_disp_order'
      try {
        this.list.forEach((e, i) => {
          e.disp_order = i + 1
        })
        const reqObj = {
          workplaceId: this.workplaceId,
          items: this.list.map(e => ({ id: e.id, disp_order: e.disp_order })),
        }
        await api.bulkUpdateDispOrder(reqObj)
        this.getList()
      } catch (err) {
        const errId = 'ERR00004'
        const msg = `${displayPageName}の${msgVars[opType]}に失敗しました。` +
          `管理者に連絡してください。` +
          `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`
        this.notifyError1(msg, { err })
      }
    },
    makeFuncGated() {
      this.saveItem = this.makeAsyncFuncGated(this.saveItem)
      this.deleteItem = this.makeAsyncFuncGated(this.deleteItem)
      this.updateDispOrder = this.makeAsyncFuncGated(this.updateDispOrder)
    },
  },
  created() {
    this.makeFuncGated()
  },
  async mounted() {
    setPageName(this, displayPageName)
    await this.ensureUserAndMasters()
    this.dispColorCandidates =
      window.master.lovs.color_set.vals.filter(e => e.group === 'dark')
    await this.getList()
    this.isGteOwner = userHasWorkplaceRoleGteOwnerSync(this, parseInt(this.workplaceId))
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
